/*@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;600&display=swap");*/
/*@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600&family=Quicksand:wght@500;700&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Pirata+One&family=Press+Start+2P&family=Red+Hat+Display:wght@300;400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  height: 100%;
  background-color: #04040a;
  background-image: "url('/public/assets/BG_Image2.jpg')";
  @apply text-white;
  
}